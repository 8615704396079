import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_loader_spinner = _resolveComponent("main-loader-spinner")!
  const _component_SidebarMain = _resolveComponent("SidebarMain")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_main_navbar = _resolveComponent("main-navbar")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createBlock(_component_el_container, {
    id: "inspire",
    class: _normalizeClass(["main-layout application", {
      'sidebar-closed': $data.drawer,
    }])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_main_loader_spinner, { loading: $data.loading }, null, 8 /* PROPS */, ["loading"]),
      ($data.isAuthorized)
        ? (_openBlock(), _createBlock(_component_el_container, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_aside, { width: "280px" }, {
                default: _withCtx(() => [
                  _createVNode(_component_SidebarMain, { onDrawerToggled: $options.drawerToggled }, null, 8 /* PROPS */, ["onDrawerToggled"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_el_container, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_header, {
                    class: "site-header",
                    height: "64px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_main_navbar)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_el_main, { class: "main-content" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_router_view)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class"]))
}