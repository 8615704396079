import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e6ce74e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel" }
const _hoisted_2 = { class: "tab-links" }
const _hoisted_3 = { class: "tab-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createVNode(_component_router_link, {
          to: $data.link.inbound
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Inbound Templates ")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["to"]),
        _createVNode(_component_router_link, {
          to: $data.link.outbound
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Outbound Templates ")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["to"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_view)
    ])
  ]))
}