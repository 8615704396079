import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  style: {"width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MappingTemplateInfoBox = _resolveComponent("MappingTemplateInfoBox")!
  const _component_CategoryMappingTemplate = _resolveComponent("CategoryMappingTemplate")!

  return (!$props.templates.length)
    ? (_openBlock(), _createBlock(_component_MappingTemplateInfoBox, { key: 0 }, {
        default: _withCtx(() => [
          _createTextVNode(" No mapping templates exists. "),
          _createElementVNode("a", {
            href: "#",
            class: "info-box-link",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => ($options.addTemplate && $options.addTemplate(...args)), ["prevent"]))
          }, " Add a template.")
        ]),
        _: 1 /* STABLE */
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.templates, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.category.id,
            "data-cy": "mapped-template-item"
          }, [
            _createVNode(_component_CategoryMappingTemplate, {
              "show-actions-buttons": true,
              "category-name": item.category.name,
              "category-path": item.category.path,
              "category-id": item.category.id,
              style: {"margin":"1vh"},
              onEditTemplate: _cache[1] || (_cache[1] = (categoryId) => _ctx.$emit('onEditTemplate', categoryId)),
              onDeleteTemplate: _cache[2] || (_cache[2] = (categoryId) => _ctx.$emit('onDeleteTemplate', categoryId))
            }, null, 8 /* PROPS */, ["category-name", "category-path", "category-id"])
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ]))
}