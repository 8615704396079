import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9ecb023c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "template-list-grid",
  "data-cy": "mapping-template-table"
}
const _hoisted_2 = { class: "grid-header" }
const _hoisted_3 = { class: "grid-body" }
const _hoisted_4 = {
  key: 0,
  class: "panel-overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_inbound_template_source_row = _resolveComponent("inbound-template-source-row")!
  const _component_InboundMappingTemplateManage = _resolveComponent("InboundMappingTemplateManage")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_row, {
          gutter: 10,
          class: "gr",
          type: "flex"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 20 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, { span: 2 }, {
                      default: _withCtx(() => [
                        _createTextVNode("Number")
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_el_col, { span: 4 }, {
                      default: _withCtx(() => [
                        _createTextVNode("Source")
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_el_col, { span: 18 })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_col, { span: 4 }, {
              default: _withCtx(() => [
                _createTextVNode("Actions")
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.mappingsList, (row, i) => {
          return (_openBlock(), _createBlock(_component_inbound_template_source_row, {
            key: row.id,
            index: i,
            row: row,
            "to-mapping": $options.mapping,
            onOnDeleteMappingTemplate: $options.deleteMappingTemplate
          }, null, 8 /* PROPS */, ["index", "row", "to-mapping", "onOnDeleteMappingTemplate"]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    (_ctx.managingMappingSource)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_InboundMappingTemplateManage, {
            onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.managingMappingSource = null))
          })
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}